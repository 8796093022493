.key-query-page > .page-content-container {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 350px auto;
}

.key-query-page > .page-content-container > .sidebar-area {
    grid-area: sidebar;
}

.key-query-page > .page-content-container > .main-area {
    grid-area: main;
}

.heading {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body {
    border-top: 0.063rem solid #8a919b;
    border-bottom: 0.063rem solid #8a919b;
    padding: 0.5rem;
}