.list-view-item-card {
    padding: 10px 10px 0px 10px;
}

.list-view-item-header {
    display: flex;
}

.list-view-item-new-tab-button {
    margin: 3px 0px;
}

.list-view-item-body {
    margin-top: -32px;
}

.list-view-item-body > ic-data-row {
    word-break: break-word;
}