.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-inline {
    margin-top: 100px;
    /* margin-bottom: 100px; */
}