.dateTimeContainer {
    width: fit-content;
}

.dateTimeBox > * {
    border: none;
    padding: 1px 8px
}

.toolTip {
    height: 24px;
    margin-left: auto;
    margin-right: 0px;
    margin-top: 30px;
  }