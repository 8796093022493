.certificate-query-page > .page-content-container {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 350px calc(100% - 350px);
}

.certificate-query-page > .sidebar-area {
    grid-area: sidebar;
}

.certificate-query-page > .main-area {
    grid-area: main;
}